<template>
  <div class="menu">
    <el-scrollbar class="scrollbar">
      <el-menu
          class="box"
          router
          unique-opened
          background-color="#304156"
          text-color="#fff"
          :default-active="activeMenu"
          active-text-color="#409EFF"
          :collapse="$store.getters.sidebarOpened"
      >
        <el-sub-menu :index="item.id+''" v-for="(item,index) in menuList" :key="index">
          <template #title>
            <i v-if="!item.meta" :class="item.icon_cls" style="margin-right:10px"></i>
            <i v-else :class="item.meta.icon" style="margin-right:10px"></i>
            <span v-if="item.meta">{{ item.meta.title }}</span>
            <span v-else>{{ item.name }}</span>
          </template>
          <el-menu-item :index="it.path" v-for="it in item.children" :key="it.id">
            <template #title>
              <i v-if="!it.meta" :class="it.icon_cls" style="margin-right:10px"></i>
              <i v-else :class="it.meta.icon" style="margin-right:10px"></i>
              <!-- <i :class="it.icon_cls" style="margin-right:10px"></i> -->
              <span v-if="it.meta">{{ it.meta.title }}</span>
              <span v-else>{{ it.name }}</span>
            </template>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </el-scrollbar>
    <span class="swithc">
      <Hamburger></Hamburger>
    </span>
  </div>
</template>

<script setup>
import {getMenulist} from '@/api/menu'
import Hamburger from '@/components/Hamburger'
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'
import {ref, computed} from 'vue'

const store = useStore()
const menuList = ref([])
const collapse = ref(false)

// 计算路由表结构
// const router = useRouter()
// const routes = computed(() => {
//   const filterRoutes = filterRouters(router.getRoutes())
//   return filterRoutes
//   // return generateMenus(filterRoutes)
// })
// // console.log(routes)

// menuList.value = routes
// console.log(menuList.value)
// 计算高亮 menu 的方法
const route = useRoute()
const activeMenu = computed(() => {
  const {meta, path} = route
  if (meta.activeMenu) {
    return meta.activeMenu
  }

  return path
})

const getMentu = async () => {
  // const { data } = await getMenulist()
  menuList.value = store.getters.menus
  // menuList.value = data
}
// console.log(menuList.value)
getMentu()
</script>


<style lang="less" scoped>
.menu {
  position: relative;
  margin-top: 20px;
}

.swithc {
  position: absolute;
  right: 10px;
  top: -10px;
  line-height: 20px;
  width: 100%;
  text-align: right;
  display: inline-block;
  cursor: pointer;
}

//.scrollbar{
//  max-height: 200px;
//}
.box {
  max-height: 700px;
}
</style>>

