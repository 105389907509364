import { createRouter, createWebHashHistory  } from 'vue-router'
import Home from '@/views/Home.vue'
// import Home from '@/layout'
import Login from '@/views/Login.vue'
// 公有权限列表111
export const publicRouter = [
	{
		path: '/',
		redirect: '/home/userInfo',
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
	},
	{
		path: '/home',
		name: 'home',
		component: Home,

		meta: {
			title: '系统首页',
			icon: 'fa fa-group',
		},
		children: [
			{
				path: '/home/userInfo',
				name: '个人中心',

				component: () => import('../views/user/UserInfo.vue'),
				meta: {
					title: '个人中心',
					icon: 'fa fa-group',
				},
			},
		],
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes: publicRouter,
})

export default router
