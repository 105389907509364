<template>
  <el-header class="header">
    <h3>后台管理系统</h3>
    <span class="Breadcrumb">
      <Breadcrumb />
    </span>
    <div class="userInfo">
      <!-- 搜索 -->
      <!-- <span class="icons">
        <svg-icon icon="search" @click="search"></svg-icon>
        <span v-show="isShow" class="search">
          <el-autocomplete
            v-model="searchVal"
            :fetch-suggestions="querySearch"
            class="inline-input"
            placeholder="请输入"
            @select="handleSelect"
          />
        </span>
      </span> -->
      <!-- 全屏 -->
      <!-- <span class="icons">
        <svg-icon icon="fullscreen"></svg-icon>
      </span> -->
      <!-- 换肤 -->
      <!-- <span class="icons">
        <svg-icon icon="fullscreen"></svg-icon>
      </span> -->
      <!-- 中英文 -->
      <!-- <span class="icons">
        <svg-icon icon="fullscreen"></svg-icon>
      </span> -->
      <!-- 登录人头像 -->
      <el-dropdown>
        <span class="el-dropdown-link">
          <el-avatar :size="50" :src="$store.getters.userInfo.avatar"></el-avatar>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <router-link to="/home/userInfo"   class="go-userinfo">
               <el-dropdown-item  >个人中心</el-dropdown-item>
              </router-link>
            <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </el-header>
</template>

<script setup>
import Breadcrumb from '@/components/Breadcrumb'
import { ref } from 'vue'
import { useStore } from 'vuex'
const stroe = useStore()
const isShow = ref(false)
const searchVal = ref('')

// 退出登录
const logout = () => {
  stroe.dispatch('user/logout')
}
// 搜索方法
const search = () => {
  isShow.value = !isShow.value
}
// 搜索
const handleSelect = () => {}
// 全局搜索方法
const querySearch = () => {}
</script>

<style lang="less" scoped>
.header {
  background: #38485c;
  line-height: 60px;
  color: #fff;
  font-size: 20px;
  width: 100vw;
  h3 {
    display: inline-block;
    line-height: 60px;
  }
  .Breadcrumb {
    position: absolute;
    display: inline-block;
    margin-left: 30px;
    line-height: 60px;
  }
  .userInfo {
    float: right;
    .icons {
      margin: 0 10px;
      cursor: pointer;
      .search {
        transition: 0.3s;
        margin-left: 10px;
        ::v-deep .el-input__inner {
          transition: 0.3s;
          width: 200px;
        }
      }
    }
  }
}
.go-userinfo{

 text-decoration: none;
}
</style>>

