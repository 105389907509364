<template>
  <router-view />
</template>

<style lang="less">
@import url('./assets/comm.css');
#app {
  height: 100vh;
  overflow: hidden;
}
/* 富文本菜单 */
.tox-tinymce-aux {
  z-index: 9999 !important;
}
</style>
