<template>
  <div class="bodys">
    <div class="login">
      <div class="title">后台管理系统</div>
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
        <el-form-item label="账号:" prop="username">
          <el-input v-model="ruleForm.username" type="text" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <el-input v-model="ruleForm.password" :type="passwordType" autocomplete="off" @change="submitForm(ruleFormRef)">
            <template #suffix>
              <el-icon class="el-input__icon" @click="onChangePwdType">
                <svg-icon :icon="passwordType === 'password' ? 'eye' : 'eye-open'"></svg-icon>
              </el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">登录</el-button>
          <el-button @click="resetForm(ruleFormRef)">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
const store = useStore()
const router = useRouter()
const ruleFormRef = ref(null)
const captche = ref(`/admin_api/users/captche?t=${new Date().getTime()}`)
const ruleForm = reactive({
  username: '',
  password: '',
  captcha: '',
})

const submitForm = (formE) => {

  console.log(formE);

  formE.validate(async (valid) => {
    if (!valid) return
    // 调用登录接口
    const data = await store.dispatch('user/login', ruleForm)

    if (data.code === 200) {


      // 调用获取用户信息
      store.dispatch('user/getUserInfo')
      // 跳转路由
      router.go(0)
      router.push('/home/userInfo')
    }
  })
}

const resetForm = (ruleFormRef) => {
  ruleFormRef.resetFields()
  captche.value = `/admin_api/users/captche?t=${new Date().getTime()}`
}
// 处理密码框文本显示状态
const passwordType = ref('password')
const onChangePwdType = () => {
  if (passwordType.value === 'password') {
    passwordType.value = 'text'
  } else {
    passwordType.value = 'password'
  }
}
const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('密码不能为空'))
  } else {
    callback()
  }
}
const validateUser = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('用户名不能为空'))
  } else {
    callback()
  }
}
const validateCode = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('验证码不能为空'))
  } else {
    callback()
  }
}
const rules = reactive({
  password: [{ validator: validatePass, trigger: 'blur' }],
  username: [{ validator: validateUser, trigger: 'blur' }],
  captcha: [{ validator: validateCode, trigger: 'blur' }],
})
</script>

<style lang="less">
.title {
  font-size: 2em;
  text-align: center;
  line-height: 60px;

}

.login {
  width: 400px;
  height: 300px;
  padding: 15px;
  border: 1px solid #ccc;

  background: #fff;
  border-radius: 3px;
}

.codInpt {
  width: 50%;
}

.code {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 32px;
  height: 32px;
  cursor: pointer;
}

.err_info {
  margin-left: 25px;
  cursor: pointer;
  color: red;
}

.bodys {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1a355b;
}
</style>
