import axios from 'axios'
import { ElMessage } from 'element-plus'
import { isCheckTimeout } from '@/utils/auth'
import store from '../store/index'

const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, timeout: 10000
})
// 添加请求拦截器
service.interceptors.request.use(
	(config) => {
		const token = window.localStorage.getItem('token')
		if (token) {
			if (isCheckTimeout()) {
				// 退出登录
				store.dispatch('user/logout')
				// return Promise.reject(new Error('token 失效了'))
				ElMessage.error('请重新登录！')
			}
			config.headers['Authorization'] = token
		}

		// 在发送请求之前做些什么
		return config
	},
	(error) => {
		// 对请求错误做些什么
		ElMessage.error(error.message)
		return Promise.reject(error.message)
	}
)

// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		// console.log(response.data)
		// const  {code}=response.data
		// if (code===401){
		// 	// console.log(1111)
		// 	// 退出登录
		// 	store.dispatch('user/logout')
		// 	return Promise.reject(new Error('token 失效了'))
		// }
		switch (response.data.code) {
			// 对得到的状态码的处理，具体的设置视自己的情况而定
			case 400:
				ElMessage.error(response.data.message)
				break
			case 401:
				// 退出登录
				ElMessage.error('token失效，请重新登录')
				store.dispatch('user/logout')
				break
			case 403:
				ElMessage.error('没有权限')
				break
			case 404:
				ElMessage.error('资源不存在')
				break
		}
		return response
	},
	(error) => {
		// 对响应错误做点什么
		switch (error) {
			// 对得到的状态码的处理，具体的设置视自己的情况而定
			case 400:
				ElMessage.error('错误')
				break
			case 401:
				ElMessage.error('权限过期了')
				break
			case 403:
				ElMessage.error('没有权限')
				break
			case 404:
				ElMessage.error('资源不存在')
				break
			case 500:
				ElMessage.error('服务器忙碌')
				break
			default:
				ElMessage.error('出错了')
				break
		}

		return Promise.reject(error)
	}
)

export const mypost = (url, data) => {
	return new Promise((resolve, reject) => {
		service({
			method: 'post',
			url: `/admin_api${url}`,
			data,
		})
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}
export const myget = (url, data) => {
	return new Promise((resolve, reject) => {
		service({
			method: 'get',
			url: `/admin_api${url}`,
			params: data,
		})
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export default service
