import { myget, mypost } from './index'
// 权限过滤后的菜单
export const getMenulist = () => myget('/menu/list')
// 所有菜单
export const getMenulists = () => myget('/menu/lists')

// 修改
export const UpdateMenu = (data) => {
	return mypost('/menu/update', data)
}

// 添加
export const addMenu = (data) => {
	return mypost('/menu/add', data)
}

export const delMenu = (data) => {
	return mypost('/menu/del', data)
}
