<template>
  <div class="panel">
    <el-badge class="item" v-for="(item,index) in arr" :key="index">
      <el-button
        plain
        @click="handleClick(index)"
        :class="currentRoutPath===item.path ? 'active':''"
      >
        {{item.title}}
        <el-icon
          class="el-icon--right mark"
          @click.stop="del(index)"
          v-if="item.path!=currentRoutPath"
        >
          <Delete />
        </el-icon>
      </el-button>
    </el-badge>
    <el-badge class="del-other">
      <el-button plain @click="delOther">关闭其他</el-button>
    </el-badge>
  </div>
</template>

<script setup>
import { Delete } from '@element-plus/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import { computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
// 获取数据
const route = useRoute()
const router = useRouter()
const store = useStore()
// 监听路由变化时触发
let arr = reactive(store.getters.tips)
// 当前的路由path
let currentRoutPath = ref(route.path)
const getRoutePath = () => {
  // 将路径添加到数组 防止重复添加
  let whiteList = ['/', '/home', '/login','/404','/403','/401']
  const res = arr.find((item) => item.path === route.path)
  // 没找到代表不重复 就添加
  if (!res){
      // 不是 whiteList 中就添加
    if (!whiteList.includes(route.path)) {
      arr.push({
        path: route.path,
        title: route.meta.title ? route.meta.title : route.name,
      })
       store.commit('app/setTips', arr)
    }
  } 
}

const handleClick = (index) => {
  router.push(arr[index].path)
  store.commit('app/setTips', arr)
}
const del = (index) => {
  // 删除的是当前选中的路由
  if (arr.length === 1) {
    return
  } else {
    if (currentRoutPath.value == arr[index].path) {
      arr.splice(index, 1)
      // 路由跳转到最近的路由
      router.push(arr[arr.length - 1].path)
    } else {
      // 只要删除，路由不跳转
      arr.splice(index, 1)
    }
  }
  // 更新vuex中tips的最新状态
  store.commit('app/setTips', arr)
}
const delOther = () => {
  for (let index = 0; index < arr.length; index++) {
    if (arr[index].path != currentRoutPath.value) {
      arr.splice(index, 1)
      index--
    }
  }
  store.commit('app/setTips', arr)
}
// 路由要深度监听
watch(
  route,
  () => {
    getRoutePath()
    currentRoutPath.value = route.path
  },
  {
    immediate: true,
  }
)
</script>
<style lang="less" scoped>
.mark {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.mark:hover {
  transform: scale(1.1);
}
.active {
  background: #409eff;
  color: #fff;
}
.item {
  margin: 0 3px;
}
.panel {
  height: 36px;

  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.del-other {
  position: absolute;
  right: 14px;
}
</style>