/**
 * 判断是否为外部资源
 */
import router from '@/router'
import store from '@/store'

export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path)
}

export function resetRouter() {
	const routers = store.getters.routers
	routers.forEach((element) => {
		if (element.component && element.component != '')
			router.removeRoute(element.component)
	})
	console.log(router.getRoutes())
}
